import axios, { AxiosInstance, ResponseType } from "axios";
import { App, Plugin } from "vue";
import { advent22Store } from "./store";

export class Advent22 {
  private axios: AxiosInstance;

  public constructor() {
    this.axios = axios.create({
      timeout: 10e3,
    });
  }

  private get api_baseurl(): string {
    // in production mode, return "//host/api"
    if (process.env.NODE_ENV === "production") {
      return `//${window.location.host}/api`;
    } else if (process.env.NODE_ENV !== "development") {
      // not in prouction or development mode
      console.warn("Unexpected NODE_ENV value");
    }

    // in development mode, return "//hostname:8000/api"
    return `//${window.location.hostname}:8000/api`;
  }

  public name_door(day: number): string {
    return `Türchen ${day}`;
  }

  public api_url(): string;
  public api_url(endpoint: string): string;
  public api_url(endpoint?: string): string {
    if (endpoint === undefined) {
      return this.api_baseurl;
    }

    while (endpoint.startsWith("/")) {
      endpoint = endpoint.substring(1);
    }

    return `${this.api_baseurl}/${endpoint}`;
  }

  private _api_get<T>(endpoint: string): Promise<T>;
  private _api_get<T>(endpoint: string, responseType: ResponseType): Promise<T>;
  private _api_get<T>(
    endpoint: string,
    responseType: ResponseType = "json",
  ): Promise<T> {
    const req_config = {
      auth: advent22Store().axios_creds,
      responseType: responseType,
    };

    return new Promise<T>((resolve, reject) => {
      this.axios
        .get<T>(this.api_url(endpoint), req_config)
        .then((response) => resolve(response.data))
        .catch((reason) => {
          console.error(`Failed to query ${endpoint}: ${reason}`);
          reject([reason, endpoint]);
        });
    });
  }

  public api_get<T>(endpoint: string): Promise<T> {
    return this._api_get<T>(endpoint);
  }

  public api_get_blob(endpoint: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this._api_get<Blob>(endpoint, "blob")
        .then((data: Blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onloadend = () => {
            if (typeof reader.result === "string") {
              resolve(reader.result);
            } else {
              reject(["failed data url", endpoint]);
            }
          };
        })
        .catch(reject);
    });
  }

  public api_put(endpoint: string, data: unknown): Promise<void> {
    const req_config = {
      auth: advent22Store().axios_creds,
    };

    return new Promise<void>((resolve, reject) => {
      this.axios
        .put(this.api_url(endpoint), data, req_config)
        .then(() => resolve())
        .catch((reason) => {
          console.error(`Failed to query ${endpoint}: ${reason}`);
          reject([reason, endpoint]);
        });
    });
  }
}

export const Advent22Plugin: Plugin = {
  install(app: App) {
    app.config.globalProperties.$advent22 = new Advent22();
  },
};
