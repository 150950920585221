<template>
  <BulmaDrawer header="Türchen bearbeiten" @open="on_open">
    <nav class="level is-mobile mb-0" style="overflow-x: auto">
      <BulmaButton
        :disabled="current_step === 0"
        class="level-item is-link"
        @click="current_step--"
        icon="fa-solid fa-backward"
      />

      <BulmaBreadcrumbs
        :steps="steps"
        v-model="current_step"
        class="level-item mb-0"
      />

      <BulmaButton
        :disabled="current_step === 2"
        class="level-item is-link"
        @click="current_step++"
        icon="fa-solid fa-forward"
      />
    </nav>

    <div class="card-content pb-0">
      <div v-if="doors.length > 0" class="content">
        <p>Für diese Tage ist ein Türchen vorhanden:</p>
        <div class="tags">
          <span
            v-for="(door, index) in doors.toSorted((a, b) => a.day - b.day)"
            :key="`door-${index}`"
            class="tag is-primary"
          >
            {{ door.day }}
          </span>
        </div>
      </div>
    </div>

    <DoorPlacer v-if="current_step === 0" :doors="doors" />
    <DoorChooser v-if="current_step === 1" :doors="doors" />
    <div v-if="current_step === 2" class="card-content">
      <Calendar :doors="doors" />
    </div>

    <footer class="card-footer is-flex is-justify-content-space-around">
      <BulmaButton
        class="card-footer-item is-danger"
        @click="on_download"
        icon="fa-solid fa-cloud-arrow-down"
        :busy="loading_doors"
        text="Laden"
      />
      <BulmaButton
        class="card-footer-item is-warning"
        @click="on_discard"
        icon="fa-solid fa-trash"
        text="Löschen"
      />
      <BulmaButton
        class="card-footer-item is-success"
        @click="on_upload"
        icon="fa-solid fa-cloud-arrow-up"
        :busy="saving_doors"
        text="Speichern"
      />
    </footer>
  </BulmaDrawer>
</template>

<script lang="ts">
import { DoorSaved } from "@/lib/api";
import { Door } from "@/lib/door";
import { advent22Store } from "@/plugins/store";
import { Options, Vue } from "vue-class-component";

import { toast } from "bulma-toast";
import Calendar from "../Calendar.vue";
import BulmaBreadcrumbs, { Step } from "../bulma/Breadcrumbs.vue";
import BulmaButton from "../bulma/Button.vue";
import BulmaDrawer from "../bulma/Drawer.vue";
import DoorChooser from "../editor/DoorChooser.vue";
import DoorPlacer from "../editor/DoorPlacer.vue";

@Options({
  components: {
    BulmaBreadcrumbs,
    BulmaButton,
    BulmaDrawer,
    DoorPlacer,
    DoorChooser,
    Calendar,
  },
})
export default class extends Vue {
  public readonly steps: Step[] = [
    { label: "Platzieren", icon: "fa-solid fa-crosshairs" },
    { label: "Ordnen", icon: "fa-solid fa-list-ol" },
    { label: "Vorschau", icon: "fa-solid fa-magnifying-glass" },
  ];
  public current_step = 0;
  public doors: Door[] = [];
  private readonly store = advent22Store();

  public loading_doors = false;
  public saving_doors = false;

  private load_doors(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$advent22
        .api_get<DoorSaved[]>("admin/doors")
        .then((data) => {
          this.doors.length = 0;

          for (const value of data) {
            this.doors.push(Door.load(value));
          }

          resolve();
        })
        .catch((error) => {
          this.store.alert_user_error(error);
          reject();
        });
    });
  }

  private save_doors(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const data: DoorSaved[] = [];

      for (const door of this.doors) {
        data.push(door.save());
      }

      this.$advent22
        .api_put("admin/doors", data)
        .then(resolve)
        .catch((error) => {
          this.store.alert_user_error(error);
          reject();
        });
    });
  }

  public on_open(ready: () => void, fail: () => void): void {
    this.load_doors().then(ready).catch(fail);
  }

  public on_download() {
    if (confirm("Aktuelle Änderungen verwerfen und Status vom Server laden?")) {
      this.loading_doors = true;

      this.load_doors()
        .then(() =>
          toast({
            message: "Erfolgreich!",
            type: "is-success",
            duration: 2e3,
          }),
        )
        .catch(() => {})
        .finally(() => (this.loading_doors = false));
    }
  }

  public on_discard() {
    if (confirm("Alle Türchen löschen? (nur lokal)")) {
      // empty `doors` array
      this.doors.length = 0;
    }
  }

  public on_upload() {
    if (confirm("Aktuelle Änderungen an den Server schicken?")) {
      this.saving_doors = true;

      this.save_doors()
        .then(() => {
          this.load_doors()
            .then(() =>
              toast({
                message: "Erfolgreich!",
                type: "is-success",
                duration: 2e3,
              }),
            )
            .catch(() => {})
            .finally(() => (this.saving_doors = false));
        })
        .catch(() => (this.saving_doors = false));
    }
  }
}
</script>
